export const environment = {
	production: false,
	identityBaseAddress: 'https://qa-identity.simplerfid.com',
	portalBaseAddress: 'https://qa-portal-service.simplerfid.com',
	printServiceBaseAddress: 'https://qa-print-service.simplerfid.com',
	emailService: {
		baseAddress: 'https://qa-email-service.simplerfid.com',
		apiVersion: 'v1',
	},
	searchDebounceTimeMilliseconds: 500,
	errorThrottleTimeMilliseconds: 10000,
	resourceBaseAddress: 'https://qasimplerfid.blob.core.windows.net',
	oldSiteBaseAddress: 'https://webqa.simplerfid.com',
	sirnalRLogLevel: 3, // TODO: Change to SignalR (typo)
	sentry: {
		dsn: 'https://7769ce721eeee0b5773ef352e31950d6@o4506074038992896.ingest.sentry.io/4506076482109440',
		environment: 'qa',
		tracePropagationTargets: [
			'qa-identity.simplerfid.com',
			'qa-portal-service.simplerfid.com',
			'qa-print-service.simplerfid.com',
		],
	},
	googleAnalytics: 'G-9KKJGM6ZB5',
	printerImageBaseUrl: '/assets/images/printers/'
};
